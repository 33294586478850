import { TGroupedRenewalEstimateBreakdown } from "src/data/billing/types/billingTypes"

/**
 * Convert breakdown map to an array sorted by home group name
 * Sort by home group name and empty home group names will be put last
 */
export function convertGroupedBreakdownToSortedArray(
  breakdown: TGroupedRenewalEstimateBreakdown
) {
  const sortedArray = breakdown.sort((a, b) => {
    const aGroupName = a.homegroupName
    const bGroupName = b.homegroupName

    if (!aGroupName && bGroupName) return 1
    if (aGroupName && !bGroupName) return -1

    if (aGroupName < bGroupName) return -1
    if (aGroupName > bGroupName) return 1

    return 0
  })

  return sortedArray
}
