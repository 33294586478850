import styled from "styled-components"

import { ErrorAlert } from "src/components/Account/BillingPortal/ErrorAlert"
import { EstimatedRenewalDetailsTable } from "src/components/Account/BillingPortal/EstimatedRenewalDetails/EstimatedRenewalDetailsTable"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { groupRenewalEstimateBreakdownByHomeGroup } from "src/data/billing/logic/billingLogic"
import {
  useFetchPlanRenewalEstimate,
  useFetchPlanRenewalEstimateBreakdown,
} from "src/data/billing/queries/billingQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { Divider } from "src/ui/Divider/Divider"
import RightArrowIcon from "src/ui/icons/right-arrow.svg"
import { BreadcrumbView } from "src/ui/Layout/BreadcrumbView"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const LIMIT = 50

export function EstimatedRenewalDetails() {
  const { offset, setOffset } = useUrlPager()

  const user = useGetUser()

  const fetchPlanRenewalEstimate = useFetchPlanRenewalEstimate({
    userId: user.user_id,
  })

  const fetchPlanRenewalEstimateBreakdown =
    useFetchPlanRenewalEstimateBreakdown({
      userId: user.user_id,
      filter: {
        limit: LIMIT,
        offset,
      },
      options: {
        select: (data) => {
          return {
            ...data,
            breakdown: groupRenewalEstimateBreakdownByHomeGroup(data.breakdown),
          }
        },
        keepPreviousData: true,
      },
    })

  const { t, langKeys } = useTranslate()

  if (
    fetchPlanRenewalEstimate.isError ||
    fetchPlanRenewalEstimateBreakdown.isError
  ) {
    return <ErrorAlert />
  }

  return (
    <BreadcrumbView
      title={
        <TitleWrapper>
          {t(langKeys.plan_renewal_estimate_breakdown_title, {
            date: fetchPlanRenewalEstimateBreakdown.data?.renewed_from,
          })}
          {!fetchPlanRenewalEstimateBreakdown.data?.renewed_from && (
            <MSkeleton inline width="10ch" />
          )}
        </TitleWrapper>
      }
      breadcrumbs={[
        {
          to: Routes.AccountBilling.location(),
          label: t(langKeys.plan_renewal_estimate_breakdown_back_breadcrumb),
        },
        {
          to: Routes.EstimatedRenewalDetails.location(),
          label: t(langKeys.plan_renewal_estimate_breakdown_breadcrumb),
        },
      ]}
      description={
        <RenewalPeriod
          renewedFrom={
            fetchPlanRenewalEstimateBreakdown.data?.renewed_from ?? ""
          }
          renewedUntil={
            fetchPlanRenewalEstimateBreakdown.data?.renewed_until ?? ""
          }
          loading={fetchPlanRenewalEstimateBreakdown.isLoading}
        />
      }
    >
      <TotalWrapper>
        <MText variant="heading3">
          {t(langKeys.plan_renewal_estimate_breakdown_total)}:{" "}
        </MText>
        <RenewalTotal
          total={fetchPlanRenewalEstimate.data?.formatted_renewal_amount ?? ""}
          loading={fetchPlanRenewalEstimate.isLoading}
        />
      </TotalWrapper>
      {!fetchPlanRenewalEstimate.isLoading && (
        <MText marginBottom={spacing.XS}>
          ({t(langKeys.properties)}:{" "}
          {fetchPlanRenewalEstimate.data?.plan_amount.formatted_amount},{" "}
          {t(langKeys.addons)}:{" "}
          {fetchPlanRenewalEstimate.data?.addon_amount.formatted_amount})
        </MText>
      )}

      <MText variant="bodyS">
        {t(langKeys.plan_renewal_estimate_breakdown_description)}
      </MText>

      <Divider $margin={spacing.L} />
      <EstimatedRenewalDetailsTable
        breakdown={fetchPlanRenewalEstimateBreakdown.data?.breakdown}
        renewedUntil={fetchPlanRenewalEstimateBreakdown.data?.renewed_until}
        loading={fetchPlanRenewalEstimateBreakdown.isLoading}
        loadingNewData={fetchPlanRenewalEstimateBreakdown.isPreviousData}
      />
      <PagerWrapper>
        <Pager
          offset={offset}
          limit={LIMIT}
          totalCount={
            fetchPlanRenewalEstimateBreakdown.data?.paging.total_count
          }
          setOffset={setOffset}
        />
      </PagerWrapper>

      <MText variant="bodyS">
        *{t(langKeys.plan_renewal_breakdown_addon_note)}
      </MText>
    </BreadcrumbView>
  )
}

function RenewalPeriod({
  renewedFrom,
  renewedUntil,
  loading,
}: {
  renewedFrom: string
  renewedUntil: string
  loading: boolean
}) {
  if (loading) {
    return <MSkeleton width="10ch" />
  }

  return (
    <PeriodWrapper>
      <MText>{renewedFrom}</MText>
      <RightArrowIcon width={24} />
      <MText>{renewedUntil}</MText>
    </PeriodWrapper>
  )
}

function RenewalTotal({ total, loading }: { total: string; loading: boolean }) {
  if (loading) {
    return <MSkeleton width="10ch" inline />
  }

  return <MText variant="heading3">{total}</MText>
}

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`

const TotalWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`

const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS3};
  margin-top: ${spacing.XS2};
  white-space: nowrap;
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL2};
`
