import styled from "styled-components"

import { convertGroupedBreakdownToSortedArray } from "src/components/Account/BillingPortal/EstimatedRenewalDetails/estimatedRenewalDetailsTableUtils"
import {
  TGroupedRenewalEstimateBreakdown,
  TPlanRenewalEstimateBreakdownResponse,
} from "src/data/billing/types/billingTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { GridTable } from "src/ui/GridTable/GridTable"
import RightArrowIcon from "src/ui/icons/right-arrow.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function EstimatedRenewalDetailsTable({
  breakdown,
  renewedUntil,
  loading,
  loadingNewData,
}: {
  breakdown?: TGroupedRenewalEstimateBreakdown
  renewedUntil?: string
  loading: boolean
  loadingNewData: boolean
}) {
  const { t, langKeys } = useTranslate()

  const breakdownArray = breakdown
    ? convertGroupedBreakdownToSortedArray(breakdown)
    : []

  const headers = [
    <MText key="home" variant="subtitleS">
      {t(langKeys.home)}
    </MText>,
    <MText key="period" variant="subtitleS">
      {t(langKeys.plan_renewal_estimate_breakdown_period)}
    </MText>,
    <MText key="days" variant="subtitleS">
      {t(langKeys.plan_renewal_estimate_breakdown_days)}
    </MText>,
    <MText key="annual-price" variant="subtitleS">
      {t(langKeys.plan_renewal_estimate_breakdown_annual_price)}
    </MText>,
    <MText key="amount" variant="subtitleS">
      {t(langKeys.plan_renewal_estimate_breakdown_amount)}*
    </MText>,
    <MText key="organization" variant="subtitleS">
      {t(langKeys.organization)}
    </MText>,
    <MText key="note" variant="subtitleS">
      {t(langKeys.plan_renewal_estimate_breakdown_note)}
    </MText>,
  ]

  const rows = breakdownArray
    .map((group) => {
      return [
        <HomeGroupTitle key={group.homegroupName} variant="subtitleS">
          {group.homegroupName ||
            t(langKeys.plan_renewal_estimate_breakdown_no_location)}
        </HomeGroupTitle>,
        group.breakdown.map((item) => (
          <BreakdownDesktopRow
            key={item.home_id}
            item={item}
            renewedUntil={renewedUntil}
          />
        )),
      ].flat()
    })
    .flat()

  return (
    <TableWrapper $dimmed={loadingNewData}>
      <GridTable
        header={headers}
        rows={rows}
        mobileRows={rows}
        horizontalScroll
        cellSize="xSmall"
        hideBorders
        disableRowHover
        hideHeaderDivider
        loading={loading}
        minWidth={0}
        templateColumns="minmax(max-content, 1fr) minmax(250px, auto) repeat(3, minmax(max-content, auto)) repeat(2, minmax(150px, auto))"
      />
    </TableWrapper>
  )
}

function BreakdownDesktopRow({
  item,
  renewedUntil,
}: {
  item: TPlanRenewalEstimateBreakdownResponse["breakdown"][number]
  renewedUntil?: string
}) {
  return (
    <>
      <MText variant="bodyS">{item.home_name}</MText>
      <div>
        <PeriodWrapper>
          <MText variant="bodyS">{item.renewed_from}</MText>
          <RightArrowIcon width={24} />
          <MText variant="bodyS">{renewedUntil}</MText>
        </PeriodWrapper>
      </div>
      <MText variant="bodyS">{item.prorated_days}</MText>
      <MText variant="bodyS">{item.price.formatted_amount}</MText>
      <MText variant="bodyS">{item.prorated_price.formatted_amount}</MText>
      <MText variant="bodyS">{item.organization_name}</MText>
      <MText variant="bodyS">{item.note}</MText>
    </>
  )
}

const TableWrapper = styled.div<{ $dimmed: boolean }>`
  opacity: ${({ $dimmed }) => ($dimmed ? 0.5 : 1)};
`

const HomeGroupTitle = styled(MText)`
  margin-top: ${spacing.L};
  grid-column-start: 1;
  grid-column-end: -1;
`

const PeriodWrapper = styled.div<{ $inline?: boolean }>`
  display: ${({ $inline }) => ($inline ? "inline-flex" : "flex")};
  align-items: center;
  gap: ${spacing.XS3};
  white-space: nowrap;
`
